.main_layout_content {
	min-height: 100%;
	position: relative;
	height: 100px;
}

.main_layout_body {
	height: 100%;
	padding: 1.25rem;
	padding-top: 0;
	padding-bottom: 1.25rem;
	padding-bottom: calc(var(--safe-area-inset-bottom, 0px) + 1.25rem);
}

.contacts_main_title {
	font-size: 21px;
	font-weight: 700;
	line-height: 1.05;
	color: #fff;
}

.contacts_content_container {
	margin: 20px -1.25rem 0;
}

.contacts_body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow-x: auto;
	padding: 0 1.25rem;
}
.hide_scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.contacts_content_container .contacts_container {
	min-width: 198px;
	border-radius: 15px;
	-webkit-box-shadow: 0 12px 19px -5px rgba(0, 0, 0, 0.13);
	box-shadow: 0 12px 19px -5px rgba(0, 0, 0, 0.13);
	background-color: #fff;
	padding: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: #0f0e27;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.contacts_content_container .contacts_container:not(:last-child) {
	margin-right: 15px;
}

.contacts_content_container .contacts_container .contacts_title {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.22;
}

.contacts_content_container .contacts_container .contacts_socials_container {
	margin-top: 10px;
}

.contacts_content_container
	.contacts_container
	.contacts_socials_container
	.contacts_social_container {
	margin-top: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.icon,
.svg {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	width: 1em;
	fill: currentColor;
}

.icon:first-child,
.svg:first-child {
	margin-left: 0;
}

.contacts_social_icon {
	font-size: 20px;
}

.contacts_content_container .contacts_container .contacts_socials_container .contacts_social {
	font-size: 14px;
	font-weight: 600;
	color: #351d78;
	text-decoration: none;
}
