/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/app.8a953496.css 
 * --------------------------------------------------------------------- 
 */

body,
html {
	margin: 0;
	padding: 0;
}

button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	border: none;
	font-family: inherit;
	margin: 0;
	outline: none;
}

html {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 100%;
}

body,
html {
	height: 100%;
}

body {
	font-smoothing: antialiased;
	line-height: 1.5;
	margin: 0;
	scroll-behavior: smooth;
	-webkit-text-decoration-skip: objects;
	text-decoration-skip: objects;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	width: 100%;
	-moz-osx-font-smoothing: grayscale;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
}

*,
:after,
:before {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
	text-align: left;
}

button {
	cursor: pointer;
}

button::-moz-focus-inner {
	border: none;
}

.shadow {
	-webkit-box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.12);
	box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.12);
}

.main_layout_body {
	min-height: 100%;
	position: relative;
	height: 100px;
}
.main_layout_content {
	min-height: 100%;
	position: relative;
	height: 100px;
}

.text {
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	max-height: 100%;
	max-width: 100%;
	position: relative;
	top: 2px;
	word-wrap: break-word;
}

.button {
	background-color: #e9eaee;
	border-color: transparent;
	border-style: solid;
	border-width: 2px;
	height: 2.5rem;
	min-width: 2.5rem;
	overflow: hidden;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
}

.button:hover {
	background-color: #e6e7ec;
}

.button {
	background-color: #fff;
	border-radius: 0.75rem;
	color: #682da2;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 700;
	line-height: 0;
	padding: 0 2rem;
	text-align: center;
	text-decoration: none;
}

.button.success {
	background-color: #00cc60;
	color: #fff;
}

.pagination {
	position: relative;
}

.pagination,
.pagination .page {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.pagination .page {
	border-radius: 50%;
	color: #afb1bb;
	cursor: pointer;
	font-weight: 500;
	height: 2rem;
	text-align: center;
	width: 2rem;
}

.pagination .page.active {
	border: 1px solid #181940;
	color: #181940;
}

.table_wrapper {
	background-color: #fff;
	border-radius: 1rem;
	overflow: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.table_container {
	height: 100%;
	overflow: auto;
	position: relative;
	width: 100%;
}

.table {
	border-collapse: separate;
	border-radius: 1rem;
	min-width: 100%;
	padding-bottom: 1.25rem;
	table-layout: fixed;
}

.thead .th {
	background-color: #fff;
	color: #3d3c59;
	font-family: "Proxima Nova Semibold", sans-serif;
	padding: 0.4rem 0.75rem;
	position: sticky;
	text-overflow: ellipsis;
	top: 0;
	white-space: nowrap;
}

.thead .th:not(:first-child):before {
	background-color: #cecece;
	bottom: 0.75rem;
	content: "";
	display: block;
	left: 0;
	position: absolute;
	top: 0.75rem;
	width: 1px;
}

.tbody .tr:active .td,
.tbody .tr:hover .td {
	background-color: #3b2f6f;
	color: #fff;
}

.tbody .td {
	color: #afb1bb;
	font-weight: 600;
	overflow: hidden;
	padding: 0.75rem;
	word-wrap: break-word;
}

.td,
.th {
	background-color: #fff;
	border-bottom: 1px solid #e5e5e5;
	line-height: 1.25;
	max-width: 50vw;
	text-align: center;
}

html {
	color: #000;
	font-family: Proxima Nova, Open Sans, sans-serif;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	min-width: 320px;
}

body {
	font-weight: 700;
}

body ::-webkit-scrollbar-thumb {
	background: rgba(104, 45, 162, 0.6);
}

body ::-webkit-scrollbar-thumb:hover {
	background: rgba(104, 45, 162, 0.8);
}

body ::-webkit-scrollbar-thumb:active {
	background: #682da2;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/mobile.b77087e4.css 
 * --------------------------------------------------------------------- 
 */

.button.mini {
	border-radius: 0.5rem;
	font-size: 0.75rem;
	height: 1.75rem;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	.button.mini {
		font-size: 16px;
	}
}

body .vm_field .vm_checkbox input:not(:checked) + .vm_checkbox_circle {
	background-color: #fff !important;
}

.table_wrapper {
	border-radius: 1.25rem;
}

.table {
	font-size: 0.75rem;
}

.thead .th {
	padding: 0.75rem 0.5rem;
}

.thead .th:not(:first-child):before {
	bottom: 0.5rem;
	top: 0.5rem;
}

.tbody .td {
	font-family: "Proxima Nova Regular", sans-serif;
	font-weight: 400;
	padding: 0.5rem;
}

:root {
	--safe-area-inset-top: env(safe-area-inset-top);
	--safe-area-inset-right: env(safe-area-inset-right);
	--safe-area-inset-bottom: env(safe-area-inset-bottom);
	--safe-area-inset-left: env(safe-area-inset-left);
}

@supports (padding-top: constant(safe-area-inset-top)) {
	:root {
		--safe-area-inset-top: constant(safe-area-inset-top);
		--safe-area-inset-right: constant(safe-area-inset-right);
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		--safe-area-inset-left: constant(safe-area-inset-left);
	}
}

body {
	overflow-y: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/753.8c5ccdd2.css 
 * --------------------------------------------------------------------- 
 */

.group_header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	margin-bottom: 0.5rem;
}

.group_header .group_header_item {
	background-color: #fff;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	line-height: 3rem;
	text-align: center;
	padding: 5px;
}

.group_header .group_header_item:not(:last-child) {
	margin-right: 2px;
}

.group_header .group_header_item:first-child {
	border-radius: 1rem 0 0 1rem;
	color: #181940;
	font-size: 1.25rem;
}

.group_header .group_header_item:last-child {
	border-radius: 0 1rem 1rem 0;
}

.group_header .group_header_item.button {
	background-color: #00cc60;
	font-size: 0.875rem;
	height: auto;
	padding: 0;
}

.text {
	display: block;
	top: 0;
	line-height: normal;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/mobile.sources.4f93893a.css 
 * --------------------------------------------------------------------- 
 */

.table_wrapper {
	margin-bottom: 1rem;
}

#sources .table_wrapper .actions {
	white-space: nowrap;
}

////////////////////////////////////////////////

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/chunk-vendors.19314803.css 
 * --------------------------------------------------------------------- 
 */

.vm_button {
	--button-margin-top: 2rem;
}

.vm_field {
	--bg-color: #fff;
	--text-color: #434343;
	--scroll-color: #fff;
	--font-size: 1rem;
	--placeholder-color: #cecece;
	--field-height: 46px;
	--field-margin: 10px;
	--padding: 1rem;
	--border-radius: 5px;
	--border-color: #009efb;
	--border-width: 1px;
	--label-color: #fff;
	--right-icon-width: 40px;
	--message-color: grey;
	--select-icon-size: 1em;
	--select-container-bg: #fff;
	--select-container-height: 200px;
	--select-container-border-top: #fff;
	--select-container-border: var(--border-color);
	--select-option-bg-hover: silver;
	--select-option-color-hover: #fff;
	--select-option-circle-bg-action: var(--validation-success);
	--select-option-height: 40px;
	--checkbox-circle-bg: transparent;
	--checkbox-circle-bg-checked: var(--validation-success);
	--validation-default: silver;
	--validation-success: #1ed700;
	--validation-error: #e00000;
	--validation-warn: orange;
	--calendar-day-circle-selected-color: var(--border-color);
	--calendar-day-text-selected-color: #fff;
	--calendar-separator-color: var(--border-color);
	--textarea-padding-vertical: 0.4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-size: var(--font-size);
	color: var(--text-color);
}

.vm_field {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.vm_field + .vm_button {
	margin-top: var(--button-margin-top);
}

.vm_field *,
.vm_field :after,
.vm_field :before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.vm_field:not(:last-child) {
	margin-bottom: var(--field-margin);
}

.vm_field .vm_input_block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
	position: relative;
}

.vm_field .vm_input {
	border-color: var(--border-color);
	background-color: var(--bg-color);
	border-style: solid;
	border-width: var(--border-width);
  font-family: "Proxima Nova Regular", sans-serif;
	font-size: 1em;
	height: var(--field-height);
	outline: none;
	padding: 0 var(--padding);
	text-decoration: none;
	white-space: nowrapReversed;
	width: 100%;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
	color: currentColor;
	border-radius: var(--border-radius);
	font-weight: 600;
	z-index: 2;
	position: relative;
}

.vm_field .vm_input.validator {
	padding-right: var(--right-icon-width);
}

.vm_field .vm_input:-webkit-inner-spin-button,
.vm_field .vm_input:-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	display: none;
	margin: 0;
}

.vm_field .vm_input:-webkit-autofill {
	-webkit-transition: background-color 86400s ease-in-out 0s;
	transition: background-color 86400s ease-in-out 0s;
	-webkit-box-shadow: inset 0 0 0 1000px transparent;
	-webkit-text-fill-color: var(--text-color) !important;
}

.vm_field .vm_input::-webkit-input-placeholder {
	color: var(--placeholder-color);
	opacity: 1;
}

.vm_field .vm_input:-moz-placeholder,
.vm_field .vm_input::-moz-placeholder {
	color: var(--placeholder-color);
	opacity: 1;
}

.vm_field .vm_input:-ms-input-placeholder {
	color: var(--placeholder-color);
	opacity: 1;
}

.vm_field .vm_textarea {
	font-family: "Proxima Nova Regular", sans-serif;
	display: block;
	line-height: 1.5;
	min-height: 7.1em;
	padding: var(--textarea-padding-vertical) var(--padding);
	resize: none;
	width: 100%;
}

.vm_field .vm_textarea + .vm_validator {
	top: 20px;
}

.vm_field .vm_label {
	color: var(--label-color);
	font-weight: 700;
	margin-left: var(--padding);
	font-size: 0.9em;
	margin-bottom: 5px;
}

.vm_validator {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	right: calc(var(--right-icon-width) / 2 - 12px);
	cursor: pointer;
	padding: 6px;
	z-index: 3;
}

.vm_validator .vm_validator_circle {
	width: 12px;
	height: 12px;
	border-radius: 100px;
	background-color: var(--validation-default);
}

*,
:after,
:before {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	display: none;
	margin: 0;
}

input:-webkit-autofill {
	-webkit-transition: background-color 86400s ease-in-out 0s;
	transition: background-color 86400s ease-in-out 0s;
	-webkit-box-shadow: inset 0 0 0 1000px transparent;
	-webkit-text-fill-color: #000 !important;
}

button {
	cursor: pointer;
}

button::-moz-focus-inner {
	border: none;
}

.circle {
	border-radius: 100000px !important;
}

.button {
	background-color: #e9eaee;
	border-color: transparent;
	border-style: solid;
	border-width: 2px;
	height: 2.5rem;
	min-width: 2.5rem;
	overflow: hidden;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
}

.button:hover {
	background-color: #e6e7ec;
}

.button {
	background-color: #fff;
	border-radius: 0.75rem;
	color: #682da2;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 700;
	line-height: 0;
	padding: 0 2rem;
	text-align: center;
	text-decoration: none;
}

.button.success {
	background-color: #00cc60;
	color: #fff;
}

.button.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

body .vm_field {
	--bg-color: #e9eaee;
	--text-color: #b1b1bb;
	--placeholder-color: #b1b1bb;
	--field-height: 2.5rem;
	--field-margin: 0.75rem;
	--padding: 0.75rem;
	--border-radius: 0.5rem;
	--border-color: transparent;
	--border-width: 2px;
	--label-color: #3d3c59;
	--scroll-color: #3d3c59;
	--select-container-bg: #e9eaee;
	--select-container-border: #e9eaee;
	--select-container-border-top: #fff;
	--select-option-bg-hover: #afb1bb;
	--select-option-color-hover: #fff;
	--checkbox-circle-bg: #fff;
	--font-size: 1rem;
	--message-color: #3b4375;
	--validation-default: silver;
	--validation-success: #1ed700;
	--validation-error: #e00000;
	--validation-warn: orange;
	--calendar-day-circle-selected-color: #3b4375;
	--calendar-separator-color: #afb1bb;
}

body .vm_field .vm_textarea {
	--min-height: 7.1em;
	min-height: var(--min-height);
}

.svg {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	width: 1em;
	fill: currentColor;
}

.svg:first-child {
	margin-left: 0;
}

.modalContainer {
	height: 100%;
	position: relative;
	width: 100%;
}

.modalContainer .modal_block {
	padding: 1rem 1.5rem;
}

@media only screen and (min-width: 0) and (max-width: 768px) {
	.modalContainer {
		padding: 0;
		padding-bottom: 0.5rem;
		padding-top: 2rem;
	}
}

html {
	color: #000;
	font-family: Proxima Nova, Open Sans, sans-serif;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	min-width: 320px;
}

body {
	font-weight: 700;
}

body ::-webkit-scrollbar-thumb {
	background: rgba(104, 45, 162, 0.6);
}

body ::-webkit-scrollbar-thumb:hover {
	background: rgba(104, 45, 162, 0.8);
}

body ::-webkit-scrollbar-thumb:active {
	background: #682da2;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/743.252b9c30.css 
 * --------------------------------------------------------------------- 
 */

.overlay {
	background: rgba(41, 26, 81, 0.9);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 200;
}

.wrapper {
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 199;
}

.wrapper.isVisible {
	z-index: 201;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/mobile.b77087e4.css 
 * --------------------------------------------------------------------- 
 */

body .vm_field .vm_checkbox input:not(:checked) + .vm_checkbox_circle {
	background-color: #fff !important;
}

:root {
	--safe-area-inset-top: env(safe-area-inset-top);
	--safe-area-inset-right: env(safe-area-inset-right);
	--safe-area-inset-bottom: env(safe-area-inset-bottom);
	--safe-area-inset-left: env(safe-area-inset-left);
}

@supports (padding-top: constant(safe-area-inset-top)) {
	:root {
		--safe-area-inset-top: constant(safe-area-inset-top);
		--safe-area-inset-right: constant(safe-area-inset-right);
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		--safe-area-inset-left: constant(safe-area-inset-left);
	}
}

body {
	overflow-y: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/4562.89360e95.css 
 * --------------------------------------------------------------------- 
 */

.Modal_root_NLdK_ {
	left: 0;
	max-height: calc(var(--vh, 1vh) * 100);
	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
	position: fixed;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 200;
}

.Modal_defaultBody_jxjP9 {
	position: relative;
}

.Modal_body_t31JW {
	margin: 24px 0;
	background-color: #fff;
	border-radius: 1.25rem;
	padding: 0 1.5rem;
	padding-bottom: 2rem;
	padding-top: 3.5rem;
	-webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

.Modal_close_v7b3H {
	color: #4f4f4f;
	cursor: pointer;
	font-size: 1rem;
	line-height: 0.7;
	margin: 0;
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	z-index: 100;
}

.Modal_close_v7b3H:hover {
	color: #000;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/common.modal.source-create.c070483a.css 
 * --------------------------------------------------------------------- 
 */

.SourceCreateModal_root_ZvFxA {
	margin: 0 auto;
	max-width: 386px;
}

@media only screen and (min-width: 0) and (max-width: 768px) {
	.SourceCreateModal_modalBody_NXniA {
		padding: 0;
	}
}
