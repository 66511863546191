.header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 1.25rem;
	position: relative;
}

.leftContent {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	z-index: 15;
}

.leftContent__message {
	font-size: 1.25rem;
	height: 1.25rem;
	line-height: 0;
	position: relative;
	text-align: center;
	color: #fff;
	width: 22px;
	height: 20px;
	// background-image: url(../../public/email.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.leftContent__message svg {
	margin: 0;
	width: 22px;
	height: 20px;
	fill: #fff;
}

.leftContent__buttons {
	width: 59px;
	height: 30px;
	border-radius: 7.5px;
	background-color: hsla(0, 0%, 100%, 0.1);
	margin-left: 10px;
	margin-right: 0;
}

.leftContent__buttonsIcons {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.leftContent__buttonsIcons img:first-child {
	margin-right: 10px;
	width: 15px;
	height: 18px;
}
.leftContent__buttonsIcons img:last-child {
	width: 15px;
	height: 18px;
}

.logo {
	font-size: 1.25rem;
	height: 1.25rem;
	line-height: 0;
	position: relative;
	text-align: center;
	z-index: 15;
}

.menuButton {
	width: 1.25rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	z-index: 10;
}

.burger {
	z-index: 10;
}

.burger .svg1 {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	width: 1em;
	fill: #fff;
	margin: 0;
	z-index: 15;
}
.burger .svg2 {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	width: 1em;
	fill: #fff;
	margin: 0;
	z-index: 15;
}

.burger input[type="checkbox"] {
	position: absolute;
	width: 0px;
	height: 0px;
	margin: 0;
	visibility: hidden;
}

.burger input[type="checkbox"]:checked ~ .svg1 {
	display: none;
}
.menuButton input[type="checkbox"]:checked ~ .svg2 {
	display: block;
}

.menu {
	position: fixed;
	left: 100vw;
	top: 0;
	width: 100vw;
	height: 100%;
	padding-top: 60px;
	background-image: radial-gradient(ellipse at bottom left, #38226a, #181840);
	opacity: 0;
	z-index: 5;
	transition: 0.5s;

	&._activeMenu {
		left: 0;
		opacity: 1;
	}
}

.activeMenu {
	left: 0;
	opacity: 1;
}

.icon,
.svg {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	width: 1em;
	fill: currentColor;
}

.menu .menuContent {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow-y: scroll;
	padding-bottom: 0;
	padding-bottom: calc(var(--safe-area-inset-bottom, 0px));
}

.menu .userInfo {
	-ms-flex-align: center;
	-ms-flex: none;
	flex: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 1rem;
}

.menu .userInfo .avatar,
.menu .userInfo {
	-webkit-box-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
}

.menu .userInfo .avatar {
	-ms-flex-align: center;
	border: 3px solid #fff;
	border-radius: 50%;
	cursor: pointer;
	-ms-flex: none;
	flex: none;
	font-size: 1.6rem;
	height: 3rem;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 0.25rem;
	position: relative;
	width: 3rem;
}

.menu .userInfo .avatar:before {
	background-color: #00ff78;
	border: 3px solid #fff;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 24px rgba(1, 40, 62, 0.36);
	box-shadow: 0 0 24px rgba(1, 40, 62, 0.36);
	content: "";
	height: 18px;
	left: -2px;
	position: absolute;
	top: -2px;
	width: 18px;
}

.menu .userInfo .username {
	font-size: 0.875rem;
}

.menu .userMoney {
	border-radius: 1rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	height: 3.75rem;
	margin: 0 1.25rem;
	overflow: hidden;
}

.menu .userMoney .groupItem {
	background-color: #3b4375;
	padding: 0.75rem 1rem;
	white-space: nowrap;
}

.menu .userMoney .groupItem:not(:last-child) {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	margin-right: 2px;
}

.moneyContainer {
	display: flex;
	align-items: center;
	padding-right: 0;
}

.menu .userMoney .moneyContainer .wallet {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 2.25rem;
	line-height: 1;
	margin-right: 0.75rem;
}

.menu .userMoney .moneyContainer .wallet svg {
	margin: 0;
}

.menu .userMoney .moneyContainer .money {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	line-height: 1;
}

.menu .userMoney .moneyContainer .money .balance {
	font-size: 1.25rem;
}

.menu .userMoney .moneyContainer .money .revshare {
	font-size: 0.75rem;
	letter-spacing: 1px;
	opacity: 0.5;
}

.menu .userMoney .actionContainer {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.menu .userMoney .actionContainer svg {
	margin: 0;
}

.menu .menuItemsContainer {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	position: relative;
}

.menu .menuItemsContainer .menuItems {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	overflow-x: auto;
	padding: 0.75rem 1.25rem;
	-webkit-overflow-scrolling: touch;
}

.menu .menuItemsContainer .menuItems .menuItem {
	background-color: #fff;
	border-radius: 1rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	overflow: hidden;
	position: relative;
	width: 148px;
	min-height: 194px;
}

.menu .menuItemsContainer .menuItems .menuItem:not(:last-child) {
	margin-right: 0.5rem;
}

.activeSubMenu > .menuItemImage {
	display: none !important;
}

.activeSubMenu > .menuItemText {
	padding: 0.5rem 1rem !important;
	position: relative !important;
	top: 2px !important;
}

.activeSubMenu > .submenu {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

.menu .menuItemsContainer .menuItems .menuItem .menuItemImage {
	bottom: 0;
	color: #350f67;

	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 50px;

	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.menu .menuItemsContainer .menuItems .menuItem .menuItemImage svg {
	margin: 0;
}

.menu .menuItemsContainer .menuItems .menuItem .menuItemText {
	color: #350f67;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-family: Proxima Nova Extrabold, sans-serif;
	font-size: 0.875rem;
	font-weight: 800;
	overflow: hidden;
	padding: 1rem;
	text-align: center;
	text-overflow: ellipsis;
	text-transform: uppercase;
	white-space: nowrap;
}

.menu .menuItemsContainer .menuItems .menuItem .submenu {
	display: none;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.menu .menuItemsContainer .menuItems .menuItem .submenu .submenuItem {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #00cc60;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	font-size: 0.75rem;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
}

.menu .menuItemsContainer .menuItems .menuItem .submenu .submenuItem:not(:last-child) {
	margin-bottom: 1px;
}

.menu .exit {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	position: relative;
	z-index: 5;
}

.menu .exit {
	-webkit-box-shadow: 0 0 54px rgba(30, 40, 50, 0.8);
	box-shadow: 0 0 54px rgba(30, 40, 50, 0.8);
	color: #492578;
	font-family: Proxima Nova Black, sans-serif;
	font-weight: 900;
	height: 2rem;
	letter-spacing: 1px;
	margin: 0.5rem auto;
	min-width: 130px;
}

.menu .mainChangeLanguage {
	margin: 10px auto 15px;
}

.changeLangItem,
.changeLangTrigger {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.changeLangText {
	color: #fff;
	text-transform: uppercase;
	line-height: 14px;
	font-weight: 500;
	font-family: Proxima Nova Medium, sans-serif;
}

.changeLangIcon {
	font-size: 16px;
	border-radius: 50%;
}
