.container {
	position: relative;
	width: 100%;
	height: fit-content;
}

.title {
	min-width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
	background-color: #e9eaee;
	color: #c9cad1;
  font-size: 16px;
  font-family: Proxima Nova Semibold, sans-serif;
	border-radius: 0.5rem;
	fill: #c9cad1;

	&._active {
		border-radius: 0.5rem 0.5rem 0px 0px;
	}
}

.title2 {
	min-width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
	background-color: #e9eaee;
	color: #c9cad1;
  font-size: 16px;
  font-family: Proxima Nova Semibold, sans-serif;
	border-radius: 0.5rem;
	fill: #c9cad1;

	&._active {
		border-radius: 0.5rem 0.5rem 0px 0px;
	}
}

.svg {
	width: 12px;
	height: 12px;

	&._active {
		transform: rotate(180deg);
	}
}
.svg2 {
	width: 1em;
	height: 1em;

	&._active {
		transform: rotate(180deg);
	}
}

.body {
	position: absolute;
	left: 0;
	top: 31px;
	width: 100%;

	padding: 0px 10px;
	background-color: #e9eaee;
	overflow: hidden;
	height: 0px;
	z-index: 3;
	border-radius: 0.5rem;

	&._active {
		padding: 5px 10px;
		border-radius: 0px 0px 0.5rem 0.5rem;
	}
}
.body2 {
	position: absolute;
	left: 0;
	top: 42px;
	width: 100%;

	padding: 0px 10px;
	background-color: #e9eaee;
	overflow: hidden;
	height: 0px;
	z-index: 99999;
	border-radius: 0.5rem;

	&._active {
		padding: 5px 10px;
		border-radius: 0px 0px 0.5rem 0.5rem;
	}
}

.item {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 0.75rem;
	color: #c9cad1;
}

.circle {
	width: 10px;
	height: 10px;
	background-color: #1ed700;
	border-radius: 50%;
}
