@import url("https://use.typekit.net/zbm3zko.css");
@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 100;
	src: local("Proxima Nova Thin"), local("ProximaNova-Thin"),
		url(../../public/fonts/ProximaNova-Thin.c89d37e3.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova Light;
	font-style: normal;
	font-weight: 300;
	src: local("Proxima Nova Light"), local("ProximaNova-Light"),
		url(../../public/fonts/ProximaNova-Light.2bdedaf5.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova Regular;
	font-style: normal;
	font-weight: 400;
	src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
		url(../../public/fonts/ProximaNova-Regular.1c6d7cd6.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova Medium;
	font-style: normal;
	font-weight: 500;
	src: local("Proxima Nova Medium"), local("ProximaNova-Medium"),
		url(../../public/fonts/ProximaNova-Medium.1e7d2ff1.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova Semibold;
	font-style: normal;
	font-weight: 600;
	src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
		url(../../public/fonts/ProximaNova-Semibold.551923a5.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova Bold;
	font-style: normal;
	font-weight: 700;
	src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
		url(../../public/fonts/ProximaNova-Bold.5a816525.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova Extrabold;
	font-style: normal;
	font-weight: 800;
	src: local("Proxima Nova Extrabold"), local("ProximaNova-Extrabold"),
		url(../../public/fonts/ProximaNova-Extrabold.66acd692.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova Black;
	font-style: normal;
	font-weight: 900;
	src: local("Proxima Nova Black"), local("ProximaNova-Black"),
		url(../../public/fonts/ProximaNova-Black.b96e3d80.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 100;
	src: local("Proxima Nova Thin Italic"), local("ProximaNova-Thin-Italic"),
		url(../../public/fonts/ProximaNova-Thin-Italic.3d92e98a.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 300;
	src: local("Proxima Nova Light Italic"), local("ProximaNova-Light-Italic"),
		url(../../public/fonts/ProximaNova-Light-Italic.e8ff5823.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 400;
	src: local("Proxima Nova Regular Italic"), local("ProximaNova-Regular-Italic"),
		url(../../public/fonts/ProximaNova-Regular-Italic.745cbbe8.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 500;
	src: local("Proxima Nova Medium Italic"), local("ProximaNova-Medium-Italic"),
		url(../../public/fonts/ProximaNova-Medium-Italic.d74a4c44.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 600;
	src: local("Proxima Nova Semibold Italic"), local("ProximaNova-Semibold-Italic"),
		url(../../public/fonts/ProximaNova-Semibold-Italic.14a33fd4.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 700;
	src: local("Proxima Nova Bold Italic"), local("ProximaNova-Bold-Italic"),
		url(../../public/fonts/ProximaNova-Bold-Italic.df838bc4.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 800;
	src: local("Proxima Nova Extrabold Italic"), local("ProximaNova-Extrabold-Italic"),
		url(../../public/fonts/ProximaNova-Extrabold-Italic.2d6e7049.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 900;
	src: local("Proxima Nova Black Italic"), local("ProximaNova-Black-Italic"),
		url(../../public/fonts/ProximaNova-Black-Italic.7f0f5d1a.woff2) format("woff2");
}
@font-face {
	font-display: swap;
	font-family: SFMono;
	font-style: normal;
	font-weight: 900;
	src: local("SFMono Heavy"), local("SFMono-Heavy"),
		url(../../public/fonts/SFMono-Heavy.096cc85f.woff2) format("woff2");
}

:root {
	--container: max(calc(50% - 360px), 20px);

	--text: #f3f5fa;
	--bg-color-1: #1c1d20;
	--bg-color-2: #2d3038;

	--neon2: #7c16b8;
	--lightning: #ffff;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: Proxima Nova Bold, Open Sans, sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 15px;
	color: var(--text);
  background-color: #f6f6f6;
	/* overflow: hidden; */
}

button {
	background-color: #fff;
	border-radius: 0.75rem;
	color: #682da2;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 700;
	line-height: 0;
	padding: 0 2rem;
	text-align: center;
	text-decoration: none;
	background-color: #e9eaee;
	border-color: transparent;
	border-style: solid;
	border-width: 2px;
	height: 2.5rem;
	min-width: 2.5rem;
	overflow: hidden;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
}

input {
	font-size: 14px;
	color: var(--text);
	box-shadow: none;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 300;
	letter-spacing: 0.5px;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

p {
	color: var(--text);
	margin: 0;
	padding: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.uiContainer {
	height: 100%;
	position: relative;
	position: relative;
	width: 100%;
	padding: 0px var(--container);
	min-height: 100vh;
	padding-top: 60px;
	background-color: #f6f6f6;
}

.uiContainerHeader {
	background-image: radial-gradient(circle at top left, #38226a, #181840);
	display: block;
	height: 250px;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 0;
}

.uiContainerHeaderDashboard {
	background-image: radial-gradient(ellipse at top, #5f2b96, #181940) !important;
}

.headerMaskWrapper {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	height: 60px;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 6;
}

.headerMaskWrapper__mask {
	background-image: radial-gradient(circle at top left, #38226a, #181840);
	height: 250px;
}
.headerMaskWrapperDashboard__mask {
	background-image: radial-gradient(ellipse at top, #5f2b96, #181940) !important;
	height: 250px;
}

.uiHeader {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	height: 60px;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 8;
}

.uiContent {
	position: relative;
	z-index: 5;
	padding-top: 75px;
}

.uiOverlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(53, 2, 129, 0.5);
	backdrop-filter: blur(5px);
	z-index: 200;

	justify-content: center;
	align-items: center;
}

.uiOverlay__loader {
	width: 100px;
	height: 100px;
}
.uiOverlay__loader svg {
	width: 100%;
	height: 100%;
}

.uiOverlayFirst {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(255, 255, 255);
	backdrop-filter: blur(5px);
	z-index: 200;

	justify-content: center;
	align-items: center;
}

.uiOverlay__loaderFirst {
	width: 100px;
	height: 100px;
}
.uiOverlay__loaderFirst svg {
	width: 100%;
	height: 100%;
}

.svg {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	width: 1em;
	fill: currentColor;
}
