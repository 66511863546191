.container {
	:global(.react-datepicker__day) {
		color: #b6b6bf !important;
		width: var(--dayWidth) !important;
	}

	:global(.react-datepicker__week) {
		height: var(--dayWidth) !important;
	}

	:global(.react-datepicker__day--selected) {
		background-color: #3b4375 !important;
		color: white !important;
		border: 1px solid #a7abc0;
	}
	:global(.react-datepicker__day--in-range) {
		color: #b6b6bf !important;
		background-color: transparent;
		border: 1px solid #a7abc0;
	}
	:global(.react-datepicker__day--keyboard-selected) {
		color: #b6b6bf !important;
		background-color: transparent;
		border: 1px solid #a7abc0;
		border-radius: 50% !important;
	}

	:global(.react-datepicker__day--range-start) {
		position: relative;
		color: white !important;
		background-color: #3b4375 !important;
	}

	:global(.react-datepicker__day--range-end) {
		color: #b6b6bf !important;
		background-color: transparent !important;
	}

	:global(.react-datepicker__day:hover) {
		// color: #b6b6bf !important;
		// background-color: transparent !important;
		border: 1px solid #a7abc0;
		border-radius: 50%;
	}

	:global(.react-datepicker__day--in-selecting-range) {
		background-color: transparent;
		color: #b6b6bf !important;
	}

	:global(.react-datepicker__current-month) {
		font-size: 16px;
		text-transform: capitalize;
		color: #b6b6bf !important;
	}

	:global(.react-datepicker__day-name) {
		color: #b6b6bf !important;
		width: var(--dayWidth) !important;
	}

	:global(.react-datepicker__navigation--previous) {
		background-color: transparent;
		border-radius: 5px;

		span {
			right: 0px;

			&:before {
				top: calc(50% - 3px);
				color: #b6b6bf !important;
			}
		}

		&:hover {
			background-color: transparent;

			span {
				&:before {
					// border-color: white !important;
				}
			}
		}
	}
	:global(.react-datepicker__navigation--next) {
		background-color: transparent;
		border-radius: 5px;

		span {
			left: 0px;

			&:before {
				top: calc(50% - 3px);
				color: #b6b6bf !important;
			}
		}

		&:hover {
			background-color: transparent;

			span {
				&:before {
					// border-color: white !important;
				}
			}
		}
	}

	:global(.react-datepicker__day--outside-month) {
		background-color: transparent !important;
		color: #b6b6bf81 !important;
	}
}

:global(.react-datepicker) {
	display: flex;
	background-color: #e9eaee;
	border: none !important;
}

@media (max-width: 720px) {
	:global(.react-datepicker) {
		flex-direction: column;
	}
}

:global(.react-datepicker__header) {
	background-color: transparent;
	border-bottom: 0px solid rgba(165, 165, 165, 0) !important;
}

:global(.react-datepicker__current-month) {
	font-size: 16px;
	text-transform: capitalize;
	color: #b6b6bf !important;
}

:global(.react-datepicker__day--outside-month) {
	background-color: transparent !important;
	color: #b6b6bf !important;
}

:global(.react-datepicker__day-names) {
	display: grid;
	grid-template-columns: repeat(7, 50px);
	gap: 2px;
	padding: 0px 10px;
	margin-top: 10px;
}

@media (max-width: 720px) {
	:global(.react-datepicker__day-names) {
		display: flex;
		justify-content: center;
	}
}

:global(.react-datepicker__day-name) {
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 16px;
	text-transform: capitalize;
	color: #b6b6bf !important;
}

@media (max-width: 480px) {
	:global(.react-datepicker__day-name) {
		width: 40px;
		margin: 0px;
	}
}

:global(.react-datepicker__month-container) {
	width: 100%;
	height: 100%;
}

:global(.react-datepicker__month) {
	width: 100%;
	margin: 0px;
	padding: 10px;
}

:global(.react-datepicker__week) {
	display: grid;
	grid-template-columns: repeat(7, 50px);
	grid-template-rows: 50px;
	gap: 2px;
	margin-bottom: 2px;
}

@media (max-width: 720px) {
	:global(.react-datepicker__week) {
		display: flex;
		justify-content: center;
		height: 40px;
	}
}

@media (max-width: 480px) {
	:global(.react-datepicker__week) {
		height: 40px;
	}
}

:global(.react-datepicker__day) {
	width: 100%;
	min-height: 100%;
	font-size: 16px;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
}

@media (max-width: 480px) {
	:global(.react-datepicker__day) {
		width: 40px;
		margin: 0px;
	}
}

:global(.react-datepicker__day--in-range) {
	border-radius: 50%;
}

:global(.react-datepicker__day--range-end) {
	border-radius: 50% !important;
}

:global(.react-datepicker__day--selecting-range-end) {
	border-radius: 50% !important;
}

:global(.react-datepicker__day--range-start) {
	border-radius: 50% !important;
}

:global(.react-datepicker__day--selecting-range-start) {
	border-radius: 50% !important;
}

:global(.react-datepicker__day--selected) {
	border-radius: 50%;
}
:global(.react-datepicker__day--in-selecting-range) {
	border-radius: 50%;
}

:global(.react-datepicker__day--range-start:not(.react-datepicker__day--range-end)) {
	border-radius: 50% !important;
}

.otherContent {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
