.vm_button {
	--button-margin-top: 2rem;
}

.vm_field {
	--bg-color: #fff;
	--text-color: #434343;
	--scroll-color: #fff;
	--font-size: 1rem;
	--placeholder-color: #cecece;
	--field-height: 46px;
	--field-margin: 10px;
	--padding: 1rem;
	--border-radius: 5px;
	--border-color: #009efb;
	--border-width: 1px;
	--label-color: #fff;
	--right-icon-width: 40px;
	--message-color: grey;
	--select-icon-size: 1em;
	--select-container-bg: #fff;
	--select-container-height: 200px;
	--select-container-border-top: #fff;
	--select-container-border: var(--border-color);
	--select-option-bg-hover: silver;
	--select-option-color-hover: #fff;
	--select-option-circle-bg-action: var(--validation-success);
	--select-option-height: 40px;
	--checkbox-circle-bg: transparent;
	--checkbox-circle-bg-checked: var(--validation-success);
	--validation-default: silver;
	--validation-success: #1ed700;
	--validation-error: #e00000;
	--validation-warn: orange;
	--calendar-day-circle-selected-color: var(--border-color);
	--calendar-day-text-selected-color: #fff;
	--calendar-separator-color: var(--border-color);
	--textarea-padding-vertical: 0.4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-size: var(--font-size);
	color: var(--text-color);
}

.vm_field {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.vm_field {
	&._disabled {
		// opacity: 0.5;
	}
}

.vm_field + .vm_button {
	margin-top: var(--button-margin-top);
}

.vm_field *,
.vm_field :after,
.vm_field :before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.vm_field:not(:last-child) {
	margin-bottom: var(--field-margin);
}

.vm_field .vm_input {
	border-color: var(--border-color);
	background-color: var(--bg-color);
	border-style: solid;
	border-width: var(--border-width);
	font-size: 1em;
	height: var(--field-height);
	outline: none;
	padding: 0 var(--padding);
	text-decoration: none;
	white-space: nowrapReversed;
	width: 100%;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
	color: currentColor;
	border-radius: var(--border-radius);
	font-weight: 600;
	z-index: 2;
	position: relative;
}

.vm_field .vm_input.validator {
	padding-right: var(--right-icon-width);
}

.vm_field .vm_input:-webkit-inner-spin-button,
.vm_field .vm_input:-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	display: none;
	margin: 0;
}

.vm_field .vm_input:-webkit-autofill {
	-webkit-transition: background-color 86400s ease-in-out 0s;
	transition: background-color 86400s ease-in-out 0s;
	-webkit-box-shadow: inset 0 0 0 1000px transparent;
	-webkit-text-fill-color: var(--text-color) !important;
}

.vm_field .vm_input::-webkit-input-placeholder {
	color: var(--placeholder-color);
	opacity: 1;
}

.vm_field .vm_input:-moz-placeholder,
.vm_field .vm_input::-moz-placeholder {
	color: var(--placeholder-color);
	opacity: 1;
}

.vm_field .vm_input:-ms-input-placeholder {
	color: var(--placeholder-color);
	opacity: 1;
}

.vm_field .vm_label {
	color: var(--label-color);
	font-weight: 700;
	margin-left: var(--padding);
	font-size: 0.9em;
	margin-bottom: 5px;
}

.vm_field .vm_icon {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	width: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	fill: currentColor;
	stroke: currentColor;
}

.vm_field .vm_icon:last-child {
	margin-right: 0;
}

.vm_field .vm_icon:first-child {
	margin-left: 0;
}

.vm_field .vm_select_container {
	width: 100%;
	position: relative;
}

.vm_field .vm_select_input {
	padding-right: var(--right-icon-width);
	text-overflow: ellipsis;
	-webkit-transition: border-radius 0.1s 0.2s;
	transition: border-radius 0.1s 0.2s;
	z-index: 2;
}

.vm_field .vm_select_input_block {
	border-radius: var(--border-radius);
	color: currentColor;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}

.vm_field .vm_select_trigger {
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: var(--right-icon-width);
	z-index: 3;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: var(--field-height);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.vm_field .vm_select_trigger .vm_icon {
	font-size: var(--select-icon-size);
}

.vm_icon {
	&._active {
		transform: rotate(180deg);
	}
}

.vm_input {
	transition: 0s !important;
	font-family: "Proxima Nova Regular", sans-serif !important;
	&._active {
		border-radius: 0.5rem 0.5rem 0px 0px !important;
	}
}

.vm_select_container {
	position: relative;
}

.variables {
	position: absolute;
	left: 0;
	top: 41px;
	width: 100%;
	padding: 0px 10px;
	background-color: #e9eaee;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: none;
	height: 0px;
	z-index: 150;
	border-radius: 0.5rem;
	transition: 0s !important;

	&._active {
		border-radius: 0px 0px 0.5rem 0.5rem;
		max-height: 250px;
		height: fit-content;
	}

	&__item {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1rem;
    font-family: "Proxima Nova Regular", sans-serif !important;
	}

	&__circle {
		width: 15px;
		height: 15px;
		background-color: #1ed700;
		border-radius: 50%;
		margin-right: 5px;
	}
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/app.8a953496.css 
 * --------------------------------------------------------------------- 
 */

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 100;
	src: local("Proxima Nova Thin"), local("ProximaNova-Thin"),
		url(https://1win.run/panel/fonts/ProximaNova-Thin.458e9735.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 300;
	src: local("Proxima Nova Light"), local("ProximaNova-Light"),
		url(https://1win.run/panel/fonts/ProximaNova-Light.cafd2b88.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 400;
	src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
		url(https://1win.run/panel/fonts/ProximaNova-Regular.652bc83a.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 500;
	src: local("Proxima Nova Medium"), local("ProximaNova-Medium"),
		url(https://1win.run/panel/fonts/ProximaNova-Medium.c065cbd3.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
		url(https://1win.run/panel/fonts/ProximaNova-Semibold.ddcb9ed8.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 700;
	src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
		url(https://1win.run/panel/fonts/ProximaNova-Bold.8881fa4c.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 800;
	src: local("Proxima Nova Extrabold"), local("ProximaNova-Extrabold"),
		url(https://1win.run/panel/fonts/ProximaNova-Extrabold.3f0ef2d3.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 900;
	src: local("Proxima Nova Black"), local("ProximaNova-Black"),
		url(https://1win.run/panel/fonts/ProximaNova-Black.f2c97d75.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 100;
	src: local("Proxima Nova Thin Italic"), local("ProximaNova-Thin-Italic"),
		url(https://1win.run/panel/fonts/ProximaNova-Thin-Italic.f3ed9f5a.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 300;
	src: local("Proxima Nova Light Italic"), local("ProximaNova-Light-Italic"),
		url(https://1win.run/panel/fonts/ProximaNova-Light-Italic.d1de132d.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 400;
	src: local("Proxima Nova Regular Italic"), local("ProximaNova-Regular-Italic"),
		url(https://1win.run/panel/fonts/ProximaNova-Regular-Italic.1bbdeb71.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 500;
	src: local("Proxima Nova Medium Italic"), local("ProximaNova-Medium-Italic"),
		url(https://1win.run/panel/fonts/ProximaNova-Medium-Italic.b381d30b.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 600;
	src: local("Proxima Nova Semibold Italic"), local("ProximaNova-Semibold-Italic"),
		url(https://1win.run/panel/fonts/ProximaNova-Semibold-Italic.b83aff94.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 700;
	src: local("Proxima Nova Bold Italic"), local("ProximaNova-Bold-Italic"),
		url(https://1win.run/panel/fonts/ProximaNova-Bold-Italic.caa5d65d.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 800;
	src: local("Proxima Nova Extrabold Italic"), local("ProximaNova-Extrabold-Italic"),
		url(https://1win.run/panel/fonts/ProximaNova-Extrabold-Italic.05aaaf43.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: Proxima Nova;
	font-style: italic;
	font-weight: 900;
	src: local("Proxima Nova Black Italic"), local("ProximaNova-Black-Italic"),
		url(https://1win.run/panel/fonts/ProximaNova-Black-Italic.182e5728.woff2) format("woff2");
}

@font-face {
	font-display: swap;
	font-family: SFMono;
	font-style: normal;
	font-weight: 900;
	src: local("SFMono Heavy"), local("SFMono-Heavy"),
		url(https://1win.run/panel/fonts/SFMono-Heavy.eab69b3e.woff2) format("woff2");
}

body,
html {
	margin: 0;
	padding: 0;
}

button,
input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	border: none;
	font-family: inherit;
	margin: 0;
	outline: none;
}

html {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 100%;
}

body,
html {
	height: 100%;
}

body {
	font-smoothing: antialiased;
	line-height: 1.5;
	margin: 0;
	scroll-behavior: smooth;
	-webkit-text-decoration-skip: objects;
	text-decoration-skip: objects;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	width: 100%;
	-moz-osx-font-smoothing: grayscale;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
}

*,
:after,
:before {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	display: none;
	margin: 0;
}

input:-webkit-autofill {
	-webkit-transition: background-color 86400s ease-in-out 0s;
	transition: background-color 86400s ease-in-out 0s;
	-webkit-box-shadow: inset 0 0 0 1000px transparent;
	-webkit-text-fill-color: #000 !important;
}

button {
	cursor: pointer;
}

button::-moz-focus-inner {
	border: none;
}

.circle {
	border-radius: 100000px !important;
}

.button {
	background-color: #e9eaee;
	border-color: transparent;
	border-style: solid;
	border-width: 2px;
	height: 2.5rem;
	min-width: 2.5rem;
	overflow: hidden;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
}

.button:hover {
	background-color: #e6e7ec;
}

.button {
	background-color: #fff;
	border-radius: 0.75rem;
	color: #682da2;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 700;
	line-height: 0;
	padding: 0 2rem;
	text-align: center;
	text-decoration: none;
}

.button.success {
	background-color: #00cc60;
	color: #fff;
}

.button.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

body .vm_field {
	--bg-color: #e9eaee;
	--text-color: #b1b1bb;
	--placeholder-color: #b1b1bb;
	--field-height: 2.5rem;
	--field-margin: 0.75rem;
	--padding: 0.75rem;
	--border-radius: 0.5rem;
	--border-color: transparent;
	--border-width: 2px;
	--label-color: #3d3c59;
	--scroll-color: #3d3c59;
	--select-container-bg: #e9eaee;
	--select-container-border: #e9eaee;
	--select-container-border-top: #fff;
	--select-option-bg-hover: #afb1bb;
	--select-option-color-hover: #fff;
	--checkbox-circle-bg: #fff;
	--font-size: 1rem;
	--message-color: #3b4375;
	--validation-default: silver;
	--validation-success: #1ed700;
	--validation-error: #e00000;
	--validation-warn: orange;
	--calendar-day-circle-selected-color: #3b4375;
	--calendar-separator-color: #afb1bb;
}

.svg {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	width: 1em;
	fill: currentColor;
}

.svg:first-child {
	margin-left: 0;
}

.modalContainer {
	height: 100%;
	position: relative;
	width: 100%;
}

.modalContainer .modal_block {
	padding: 1rem 1.5rem;
}

@media only screen and (min-width: 0) and (max-width: 768px) {
	.modalContainer {
		padding: 0;
		padding-bottom: 0.5rem;
		padding-top: 2rem;
	}
}

html {
	color: #000;
	font-family: Proxima Nova, Open Sans, sans-serif;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	min-width: 320px;
}

body {
	font-weight: 700;
}

body ::-webkit-scrollbar-thumb {
	background: rgba(104, 45, 162, 0.6);
}

body ::-webkit-scrollbar-thumb:hover {
	background: rgba(104, 45, 162, 0.8);
}

body ::-webkit-scrollbar-thumb:active {
	background: #682da2;
}

.hide_scrollbar::-webkit-scrollbar {
	display: none;
}

.hide_scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/743.252b9c30.css 
 * --------------------------------------------------------------------- 
 */

.overlay[data-v-e4975e44] {
	background: rgba(41, 26, 81, 0.9);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 200;
}

.wrapper[data-v-e4975e44] {
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 199;
}

.wrapper {
	&._isVisible {
		z-index: 201;
	}
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/mobile.b77087e4.css 
 * --------------------------------------------------------------------- 
 */

body .vm_field .vm_checkbox input:not(:checked) + .vm_checkbox_circle {
	background-color: #fff !important;
}

:root {
	--safe-area-inset-top: env(safe-area-inset-top);
	--safe-area-inset-right: env(safe-area-inset-right);
	--safe-area-inset-bottom: env(safe-area-inset-bottom);
	--safe-area-inset-left: env(safe-area-inset-left);
}

@supports (padding-top: constant(safe-area-inset-top)) {
	:root {
		--safe-area-inset-top: constant(safe-area-inset-top);
		--safe-area-inset-right: constant(safe-area-inset-right);
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		--safe-area-inset-left: constant(safe-area-inset-left);
	}
}

body {
	overflow-y: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/4562.89360e95.css 
 * --------------------------------------------------------------------- 
 */

.Modal_root_NLdK_ {
	left: 0;
	max-height: calc(var(--vh, 1vh) * 100);
	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
	position: fixed;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 200;
}

.Modal_defaultBody_jxjP9 {
	position: relative;
}

.Modal_body_t31JW {
	margin: 24px 0;
	background-color: #fff;
	border-radius: 1.25rem;
	padding: 0 1.5rem;
	padding-bottom: 2rem;
	padding-top: 3.5rem;
	-webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

.Modal_close_v7b3H {
	color: #4f4f4f;
	cursor: pointer;
	font-size: 1rem;
	line-height: 0.7;
	margin: 0;
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	z-index: 100;
}

.Modal_close_v7b3H:hover {
	color: #000;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/common.modal.statistics-caution.579ce1b2.css 
 * --------------------------------------------------------------------- 
 */

.StatisticsSubIdModal_root_g06uJ {
	margin: 0 auto;
	max-width: 386px;
}

@media only screen and (min-width: 0) and (max-width: 768px) {
	.StatisticsSubIdModal_modalBody_KpXyw {
		padding: 0;
	}
}

.overlay {
	background: rgba(41, 26, 81, 0.9);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 200;
}
