// .infoWrapper {
// 	position: relative;
// 	color: #181940;
// 	min-height: 40px;
// 	background-color: #fff;
// 	border-radius: 1.25rem;
// 	margin-bottom: 0.5rem;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// 	box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.12);
// }

// .title {
// 	display: flex;
// 	justify-content: flex-start;
// 	align-items: center;
// 	background-color: #fff;
// 	border-bottom-left-radius: 0;
// 	border-bottom-right-radius: 0;
// 	font-size: 1.25rem;
// 	padding: 1rem;
// 	position: relative;
// 	background-color: #fff;
// 	border-radius: 1rem;
// 	color: #4f4f4f;
// 	position: relative;
// 	width: 100%;
// 	z-index: 1;
// }

// .icon {
// 	margin: 0;
// 	color: #3d3c59;
// 	height: 35px;
// 	width: 35px;
// 	margin-right: 10px;
// }

// .menu {
// 	position: relative;
// 	display: flex;
// 	justify-content: flex-start;
// 	align-items: center;
// }

// .menuTitle {
// 	position: relative;
// 	color: #3d3c59;
// 	line-height: 1.5;
// 	font-family: Proxima Nova Extrabold, sans-serif;

// 	&:before {
// 		content: "";
// 		background-color: #682da2;
// 		border-radius: 4px;
// 		bottom: 0;
// 		height: 5px;
// 		left: -4px;
// 		position: absolute;
// 		right: -4px;
// 	}
// }

// .trigger {
// 	width: 40px;
// 	height: 2.5rem;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;

// 	.icon {
//     font-size: 18px;
// 		width: 1em;
// 		height: 1em;
// 	}
// }

// .selector {
// 	position: absolute;
// 	left: -5%;
// 	bottom: 0px;
// 	transform: translateY(100%);
// 	width: 170px;
// 	height: 0px;
// 	background-color: #ffffff;
// 	overflow: hidden;

// 	&._active {
// 		height: 120px;
// 	}

// 	.selectorItem {
// 		height: 40px;
// 		padding: 0.75rem;
// 		font-size: 1rem;
// 		color: #c8cbd6;

// 		&:after {
// 			content: "";
// 			position: absolute;
//       right: 0.75rem;
// 			background-color: #1ed700;
// 			border-radius: 20px;
// 			width: 1em;
// 			height: 1em;
// 		}

// 		&._active {
// 		}
// 	}
// }

.vmField {
	--bg-color: #fff;
	--text-color: #434343;
	--scroll-color: #fff;
	--font-size: 1rem;
	--placeholder-color: #cecece;
	--field-height: 46px;
	--field-margin: 10px;
	--padding: 1rem;
	--border-radius: 5px;
	--border-color: #009efb;
	--border-width: 1px;
	--label-color: #fff;
	--right-icon-width: 40px;
	--message-color: grey;
	--select-icon-size: 1em;
	--select-container-bg: #fff;
	--select-container-height: 200px;
	--select-container-border-top: #fff;
	--select-container-border: var(--border-color);
	--select-option-bg-hover: silver;
	--select-option-color-hover: #fff;
	--select-option-circle-bg-action: var(--validation-success);
	--select-option-height: 40px;
	--checkbox-circle-bg: transparent;
	--checkbox-circle-bg-checked: var(--validation-success);
	--validation-default: silver;
	--validation-success: #1ed700;
	--validation-error: #e00000;
	--validation-warn: orange;
	--calendar-day-circle-selected-color: var(--border-color);
	--calendar-day-text-selected-color: #fff;
	--calendar-separator-color: var(--border-color);
	--textarea-padding-vertical: 0.4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-size: var(--font-size);
	color: var(--text-color);
}

.vmField {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.vmField *,
.vmField :after,
.vmField :before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.vmField:not(:last-child) {
	margin-bottom: var(--field-margin);
}

.vmField .vmInput {
	border-color: var(--border-color);
	background-color: var(--bg-color);
	border-style: solid;
	border-width: var(--border-width);
	font-size: 1em;
	height: var(--field-height);
	outline: none;
	padding: 0 var(--padding);
	text-decoration: none;
	white-space: nowrapReversed;
	width: 100%;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
	color: currentColor;
	border-radius: var(--border-radius);
	font-weight: 600;
	z-index: 2;
	position: relative;
}

.vmField .vmInput.validator {
	padding-right: var(--right-icon-width);
}

.vmField .vmInput:-webkit-inner-spin-button,
.vmField .vmInput:-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	display: none;
	margin: 0;
}

.vmField .vmInput:-webkit-autofill {
	-webkit-transition: background-color 86400s ease-in-out 0s;
	transition: background-color 86400s ease-in-out 0s;
	-webkit-box-shadow: 0 0 0 1000px transparent inset;
	-webkit-text-fill-color: var(--text-color) !important;
}

.vmField .vmInput::-webkit-input-placeholder {
	color: var(--placeholder-color);
	opacity: 1;
}

.vmField .vmInput:-moz-placeholder,
.vmField .vmInput::-moz-placeholder {
	color: var(--placeholder-color);
	opacity: 1;
}

.vmField .vmInput:-ms-input-placeholder {
	color: var(--placeholder-color);
	opacity: 1;
}

.vmField .vmIcon {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	width: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	fill: currentColor;
	stroke: currentColor;
}

.vmField .vmIcon:last-child {
	margin-right: 0;
}

.vmField .vmIcon:first-child {
	margin-left: 0;
}

.vmField .vm-select-container {
	width: 100%;
	position: relative;
}

.vmField .vmSelectInput {
	padding-right: var(--right-icon-width);
	text-overflow: ellipsis;
	-webkit-transition: border-radius 0.1s 0.2s;
	transition: border-radius 0.1s 0.2s;
	z-index: 2;
}

.vmField .vmSelectInputBlock {
	border-radius: var(--border-radius);
	color: currentColor;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}

.vmField .vmSelectInputOverlay {
	bottom: 0;
	cursor: pointer;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 2;
}

.vmField .vmSelectTrigger {
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: var(--right-icon-width);
	z-index: 3;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: var(--field-height);
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.vmField .vmSelectTrigger .vmIcon {
	font-size: var(--select-icon-size);
}

input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	border: none;
	font-family: inherit;
	margin: 0;
	outline: none;
}

html {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 100%;
}

body,
html {
	height: 100%;
}

body {
	font-smoothing: antialiased;
	line-height: 1.5;
	margin: 0;
	scroll-behavior: smooth;
	-webkit-text-decoration-skip: objects;
	text-decoration-skip: objects;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	width: 100%;
	-moz-osx-font-smoothing: grayscale;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
}

*,
:after,
:before {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	display: none;
	margin: 0;
}

input:-webkit-autofill {
	-webkit-transition: background-color 86400s ease-in-out 0s;
	transition: background-color 86400s ease-in-out 0s;
	-webkit-box-shadow: 0 0 0 1000px transparent inset;
	-webkit-text-fill-color: #000 !important;
}

.shadow {
	-webkit-box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.12);
	box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.12);
}

body .vmField {
	--bg-color: #e9eaee;
	--text-color: #b1b1bb;
	--placeholder-color: #b1b1bb;
	--field-height: 2.5rem;
	--field-margin: 0.75rem;
	--padding: 0.75rem;
	--border-radius: 0.5rem;
	--border-color: transparent;
	--border-width: 2px;
	--label-color: #3d3c59;
	--scroll-color: #3d3c59;
	--select-container-bg: #e9eaee;
	--select-container-border: #e9eaee;
	--select-container-border-top: #fff;
	--select-option-bg-hover: #afb1bb;
	--select-option-color-hover: #fff;
	--checkbox-circle-bg: #fff;
	--font-size: 1rem;
	--message-color: #3b4375;
	--validation-default: silver;
	--validation-success: #1ed700;
	--validation-error: #e00000;
	--validation-warn: orange;
	--calendar-day-circle-selected-color: #3b4375;
	--calendar-separator-color: #afb1bb;
}

.svg {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	height: 1em;
	margin-left: 0.75em;
	margin-right: 0.75em;
	width: 1em;
	fill: currentColor;
}

.svg:first-child {
	margin-left: 0;
}

.titleContainer {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	border-radius: 1rem;
	color: #4f4f4f;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	font-size: 1.75rem;
	font-family: Proxima Nova Extrabold, sans-serif;
	font-weight: 800;
	padding: 1rem 1.25rem;
	padding-left: 2rem;
	position: relative;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	width: 100%;
	z-index: 1;
}

.titleContainer .titleIcon {
	color: #3d3c59;
	height: 1.75em;
	margin-right: 0.5em;
	width: 1.75em;
}

.titleContainer .titleText {
	color: #3d3c59;
	position: relative;
	top: -2px;
}

.titleContainer .titleText:after {
	background-color: #682da2;
	border-radius: 4px;
	bottom: 0;
	content: "";
	height: 5px;
	left: -4px;
	position: absolute;
	right: -4px;
}

html {
	color: #000;
	font-family: Proxima Nova, Open Sans, sans-serif;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

body {
	font-weight: 700;
}

body ::-webkit-scrollbar-thumb {
	background: rgba(104, 45, 162, 0.6);
}

body ::-webkit-scrollbar-thumb:hover {
	background: rgba(104, 45, 162, 0.8);
}

body ::-webkit-scrollbar-thumb:active {
	background: #682da2;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/mobile.640f8117.css 
 * --------------------------------------------------------------------- 
 */

body .vmField .vm-checkbox input:not(:checked) + .vm-checkbox-circle {
	background-color: #fff !important;
}

.dashboardInfo {
	border-radius: 1.25rem;
	margin-bottom: 1rem;
	overflow: hidden;
}

.dashboardInfo .infoHeader {
	background-color: #fff;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	font-size: 1.25rem;
	padding: 1rem;
	position: relative;
}

.dashboardInfo .infoHeader .titleIcon {
	margin-right: 0;
}

.dashboardInfo .infoHeader .titleText {
	left: 60px;
	pointer-events: none;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 7;
}

.dashboardInfo .infoBody {
	background-color: #fff;
	color: #c8cbd6;
	padding: 0 0.75rem;
}

.dashboardInfo .infoBody .infoBlock {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 0.875rem;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	line-height: 1;
	padding: 0.75rem 0.5rem;
	white-space: nowrap;
}

.dashboardInfo .infoBody .infoBlock:not(:last-child) {
	border-bottom: 1px solid currentColor;
}

.dashboardInfo .infoBody .infoBlock .infoBlockText {
	color: #584c7f;
  font-family: Proxima Nova Semibold, sans-serif;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dashboardInfo .infoBody .infoBlock .infoBlockValue {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 1000px;
	color: #584c7f;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 800;
	height: 16px;
}

.dashboardInfo .infoFooter {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #682da2;
	background-image: -webkit-gradient(linear, left top, right top, from(#682da2), to(#181940));
	background-image: linear-gradient(90deg, #682da2, #181940);
	border-radius: inherit;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-family: Proxima Nova Semibold, sans-serif;
	font-weight: 600;
	height: 50px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.dashboardInfo .infoFooter .infoFooterValue {
	font-weight: 600;
}

.dashboardInfo .infoFooter .infoFooterText {
	margin-right: 0.25rem;
}

.dashboardInfo .infoFooter .infoFooterText :after {
	content: ":";
}

.dashboardInfo .dashboardTime {
	position: relative;
	width: 170px;
	--bg-color: #fff;
	--field-margin: 0;
	--select-container-bg: #fff;
	--select-container-border: #fff;
	--text-color: #3d3c59;
}

.selector {
	position: absolute;
	left: 0;
	bottom: 0;
	transform: translateY(100%);
	background-color: white;
	width: 100%;
	height: 0px;
	overflow: hidden;

	&._active {
		height: 160px;
	}

	.selectorItem {
		height: 40px;
		padding: 0 0.75rem;
		font-size: 0.9em;
		align-items: 100%;
		color: #c8cbd6;
		display: flex;
		align-items: center;

		&._active {
			&:after {
				content: "";
				position: absolute;
				right: 0.75rem;
				background-color: #1ed700;
				border-radius: 20px;
				width: 1em;
				height: 1em;
			}
		}
	}
}

.dashboardInfo .dashboardTime .vmSelectTrigger {
	font-size: 18px;
}

.dashboardInfo .dashboardTime .vmSelectInput {
	color: transparent;
}
