.mainLayoutContentWrapper {
	height: 100%;
}

.mainLayoutContent {
	min-height: 100%;
	position: relative;
	height: 100px;
}

.mainLayoutBody {
	height: 100%;
	// padding: 1.25rem;
	padding-top: 0;
	padding-bottom: 1.25rem;
	padding-bottom: calc(var(--safe-area-inset-bottom, 0px) + 1.25rem);
}

#statistics {
	height: 100%;
}

.statisticsHeader {
	height: 30px;
}

.statisticsFilters {
	background-color: #fff;
	border-radius: 1.25rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 0.5rem;
}

.filtersForm {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 1.25rem 1rem;
}

.tableWrapper {
	position: relative;
	background-color: #fff;
	border-radius: 1rem;
	overflow: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	height: calc(100vh - 240px - 20px - 0.5rem);
	box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.12);
}

.tableContainer {
	height: 100%;
	overflow: auto;
	position: relative;
	width: 100%;
}

.table {
	border-collapse: collapse;
	border-radius: 1rem;
	min-width: 100%;
	padding-bottom: 1.25rem;
	table-layout: fixed;
	font-size: 0.8rem;
}

.thead .th {
	background-color: #fff;
	color: #3d3c59;
	font-weight: 600;
	// padding: 0.4rem 0.75rem;
	position: sticky;
	text-overflow: ellipsis;
	top: 0;
	white-space: nowrap;
}

.thead .th:not(:first-child):before {
	background-color: #cecece;
	bottom: 0.75rem;
	content: "";
	display: block;
	left: 0;
	position: absolute;
	top: 0.75rem;
	width: 1px;
}

.thDate {
	z-index: 200 !important;
}

.tbody .tr:active .td,
.tbody .tr:hover .td {
	background-color: #3b2f6f;
	color: #fff;
}
.tbody .tr:active .left,
.tbody .tr:hover .left {
	background-color: #3b2f6f;
	color: #fff;
}

.tbody .td {
	color: #afb1bb;
	font-weight: 600;
	overflow: hidden;
	padding: 0.5rem;
	word-wrap: break-word;
}

.left {
	font-family: Proxima Nova Regular, sans-serif;
	font-weight: 400;
	color: #3b4375;
	padding: 0.5rem;
	z-index: 1;
}

.td,
.th {
	background-color: #fff;
	border-bottom: 1px solid #e5e5e5;
	line-height: 1.25;
	max-width: 50vw;
	text-align: center;
}

.left,
.left {
	white-space: nowrap;
}

.left {
	background-color: #fafafa;
	left: 0;
	position: sticky;
}

.filtersForm {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 1.25rem 1rem;
}

body .vmField:hover .vmInputCheckbox {
	--select-option-bg-hover: var(--bg-color);
	--select-option-color-hover: #b1b1bb;
}

body .vmField .vmCheckbox input:not(:checked) + .vmCheckboxCircle {
	background-color: #fff !important;
}

.tableWrapper {
	border-radius: 1.25rem;
}

.table {
	font-size: 0.8rem;
}

.thead .th {
	padding: 0.75rem 0.5rem;
	background-color: #fff;
	color: #3d3c59;
	font-family: Proxima Nova Semibold, sans-serif;
	font-weight: 600;
	position: sticky;
	text-overflow: ellipsis;
	top: 0;
	white-space: nowrap;
}

.thead .th:not(:first-child):before {
	bottom: 0.5rem;
	top: 0.5rem;
}

.tbody .td {
	font-family: Proxima Nova Regular, sans-serif;
	font-weight: 400;
	padding: 0.5rem;
	// white-space: nowrap;
}

.title {
	color: #fff;
	display: inline-block;
	font-size: 24px;
	font-weight: 800;
	line-height: 1.25;
	padding-left: 1rem;
	position: relative;
}

.title:before {
	background-color: #00cc60;
	border-radius: 50%;
	content: "";
	display: block;
	height: 4px;
	left: 0.5rem;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 4px;
}

.subtitle {
	position: relative;
	color: #fff;
	display: inline-block;
	font-family: Proxima Nova Regular, sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
}

.subtitle:before {
	content: "/";
	margin: 0 0.5rem;
}

.myFilters {
	position: relative;
	color: #181940;
	min-height: 40px;
	background-color: #fff;
	border-radius: 1.25rem;
	margin-bottom: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.12);

	&._active {
		// border-radius: 0.5rem;
	}
}

.myFilters__title {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
	padding: 0px 1rem;
	font-size: 0.75rem;

	.svg {
		font-size: 1rem;
	}

	&._active {
		display: none;
	}
}

.myFilters__body {
	display: flex;
	padding: 1rem;
	width: 100%;
	flex-direction: column;
	gap: 5px;

	&._active {
	}
}

.foo {
	position: relative;
	background-color: #e9eaee;
	border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
	font-size: 16px !important;
	overflow: hidden;

	&._active {
		overflow: visible;
	}
}

.seeDate {
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
	background-color: #e9eaee;
	border-radius: 0.5rem;

	&._active {
		position: relative;
		border-radius: 0.5rem 0.5rem 0px 0px;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -5px;
			width: 100%;
			height: 10px;
			background-color: #e9eaee;
		}
	}
}

.seeDate__date {
	width: 100%;
	text-align: center;
	color: #c5c5cd;
}

.seeDate__icon {
	width: 1em;
	height: 1em;
	font-size: 1em;
	fill: #c5c5cd;
}

.seeDate__picker {
	display: block;
	position: absolute;
	top: 30px;
	left: 0;
	width: 100%;
	height: 0px;
	z-index: 4;

	&._active {
		display: block;
	}
}

.detailing {
	width: 100%;
	height: 30px;
	padding: 0px 10px;
	background-color: #e9eaee;
	border-radius: 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #c5c5cd;

	.circle {
		width: 10px;
		height: 10px;
		background-color: white;
		border-radius: 50%;

		&._active {
			background-color: #1ed700;
		}
	}
}

.line {
	width: 100%;
	height: 1px;
	background-color: #e9eaee;
	margin: 5px 0px;
}

.subId {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	&__label {
		margin-left: 0.5rem;
	}

	&__body {
		width: 100%;
		height: 30px;
		padding: 0px 10px;
		background-color: #e9eaee;
		border-radius: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #c5c5cd;
	}
}

.buttonActive {
	background-color: #00cc60;
	color: #fff;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 700;
	line-height: 0;
	padding: 0 2rem;
	text-align: center;
	text-decoration: none;
	border-radius: 100000px;
}
