body,
html {
	margin: 0;
	padding: 0;
}

html {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 100%;
}

body,
html {
	height: 100%;
}

body {
	font-smoothing: antialiased;
	line-height: 1.5;
	margin: 0;
	scroll-behavior: smooth;
	-webkit-text-decoration-skip: objects;
	text-decoration-skip: objects;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	width: 100%;
	-moz-osx-font-smoothing: grayscale;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
}

*,
:after,
:before {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

.shadow {
	-webkit-box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.12);
	box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.12);
}

html {
	color: #000;
	font-family: Proxima Nova, Open Sans, sans-serif;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	min-width: 320px;
}

body {
	font-weight: 700;
}

body ::-webkit-scrollbar-thumb {
	background: rgba(104, 45, 162, 0.6);
}

body ::-webkit-scrollbar-thumb:hover {
	background: rgba(104, 45, 162, 0.8);
}

body ::-webkit-scrollbar-thumb:active {
	background: #682da2;
}

/* ---------------------------------------------------------------------
 * https://1win.run/panel/css/mobile.b77087e4.css 
 * --------------------------------------------------------------------- 
 */

body .vm_field .vm_checkbox input:not(:checked) + .vm_checkbox_circle {
	background-color: #fff !important;
}

.title {
	color: #fff;
	display: inline-block;
	font-size: 24px;
	font-family: "Proxima Nova Extrabold", sans-serif;
	line-height: 1.25;
	padding-left: 1rem;
	position: relative;
}

.title:before {
	background-color: #00cc60;
	border-radius: 50%;
	content: "";
	display: block;
	height: 4px;
	left: 0.5rem;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 4px;
}

:root {
	--safe-area-inset-top: env(safe-area-inset-top);
	--safe-area-inset-right: env(safe-area-inset-right);
	--safe-area-inset-bottom: env(safe-area-inset-bottom);
	--safe-area-inset-left: env(safe-area-inset-left);
}

@supports (padding-top: constant(safe-area-inset-top)) {
	:root {
		--safe-area-inset-top: constant(safe-area-inset-top);
		--safe-area-inset-right: constant(safe-area-inset-right);
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		--safe-area-inset-left: constant(safe-area-inset-left);
	}
}

body {
	overflow-y: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

#help {
	height: auto;
}

.questions {
	padding-bottom: 2rem;
}

.questions .question {
	border-radius: 1.5rem;
	cursor: pointer;
	margin-bottom: 0.75rem;
	overflow: hidden;
	text-align: justify;
}

.questions .question .question_header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 1.5rem;
	color: #7d7d7d;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	line-height: 1.25;
	min-height: 3.75rem;
}

.questions .question .question_body,
.questions .question .question_header {
	background-color: #fff;
	font-size: 0.75rem;
	padding: 0.75rem 1.5rem;
	position: relative;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.questions .question .question_body {
	border-bottom-left-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
	color: #4f4f4f;
	display: none;
	padding-bottom: 1.25rem;
	white-space: pre-line;
}

.activeHeader {
	background-image: -webkit-gradient(linear, left top, right top, from(#5f2f9a), to(#271f54));
	background-image: linear-gradient(90deg, #5f2f9a, #271f54);
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;

	background-color: #fff;
	font-size: 0.75rem;
	padding: 0.75rem 1.5rem;
	position: relative;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 1.5rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	line-height: 1.25;
	min-height: 3.75rem;
	color: #fff !important;
}

.activeBody {
	display: block;

	border-bottom-left-radius: 1.5rem;
	border-bottom-right-radius: 1.5rem;
	color: #4f4f4f;
	display: block !important;
	padding-bottom: 1.25rem;
	white-space: pre-line;

	background-color: #fff;
	font-size: 0.75rem;
	padding: 0.75rem 1.5rem;
	position: relative;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}
